var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-form-group',{attrs:{"label":"ยอดถอนขั้นต่ำ *","label-for":"minWithdrawCredit"}},[_c('ValidationProvider',{attrs:{"name":"minWithdrawCredit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minWithdrawCredit","state":errors[0] ? false : null},model:{value:(_vm.form.minWithdrawCredit),callback:function ($$v) {_vm.$set(_vm.form, "minWithdrawCredit", $$v)},expression:"form.minWithdrawCredit"}})]}}])})],1),_c('b-form-group',{attrs:{"label":"ถอนออโต้ (บาท)","label-for":"maxAutoWithdraw"}},[_c('ValidationProvider',{attrs:{"name":"maxAutoWithdraw","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"maxAutoWithdraw","state":errors[0] ? false : null},model:{value:(_vm.form.maxAutoWithdraw),callback:function ($$v) {_vm.$set(_vm.form, "maxAutoWithdraw", $$v)},expression:"form.maxAutoWithdraw"}}),_c('b-form-text',[_vm._v(" เมื่อยูสเซอร์ถอนเงินน้อยกว่ายอดด้านบน ระบบจะทำการอนุมัติรายการถอนให้อัตโนมัติ ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"จำนวนถอนสูงสุดต่อวัน / ครั้ง *","label-for":"maxWithdrawCreditTimePerDay"}},[_c('ValidationProvider',{attrs:{"name":"maxWithdrawCreditTimePerDay","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"maxWithdrawCreditTimePerDay","state":errors[0] ? false : null},model:{value:(_vm.form.maxWithdrawCreditTimePerDay),callback:function ($$v) {_vm.$set(_vm.form, "maxWithdrawCreditTimePerDay", $$v)},expression:"form.maxWithdrawCreditTimePerDay"}})]}}])})],1),_c('b-form-group',{attrs:{"label":"จำนวนรอบแทงขั้นต่ำ ครบกำหนดจะสามารถถอนได้ (รอบ)","label-for":"limitWithdrawBetTxn"}},[_c('ValidationProvider',{attrs:{"name":"limitWithdrawBetTxn","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"limitWithdrawBetTxn","state":errors[0] ? false : null},model:{value:(_vm.form.limitWithdrawBetTxn),callback:function ($$v) {_vm.$set(_vm.form, "limitWithdrawBetTxn", $$v)},expression:"form.limitWithdrawBetTxn"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }