<template>
  <b-card>
    <b-form-group
        label="ยอดถอนขั้นต่ำ *"
        label-for="minWithdrawCredit"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="minWithdrawCredit"
          rules="required"
      >
        <b-form-input
            id="minWithdrawCredit"
            v-model="form.minWithdrawCredit"
            :state="errors[0] ? false : null"
        ></b-form-input>
      </ValidationProvider>
    </b-form-group>
    <b-form-group
        label="ถอนออโต้ (บาท)"
        label-for="maxAutoWithdraw"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="maxAutoWithdraw"
          rules="required"
      >
        <b-form-input
            id="maxAutoWithdraw"
            v-model="form.maxAutoWithdraw"
            :state="errors[0] ? false : null"
        ></b-form-input>
        <b-form-text>
          เมื่อยูสเซอร์ถอนเงินน้อยกว่ายอดด้านบน
          ระบบจะทำการอนุมัติรายการถอนให้อัตโนมัติ
        </b-form-text>
      </ValidationProvider>
    </b-form-group>
    <b-form-group
        label="จำนวนถอนสูงสุดต่อวัน / ครั้ง *"
        label-for="maxWithdrawCreditTimePerDay"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="maxWithdrawCreditTimePerDay"
          rules="required"
      >
        <b-form-input
            id="maxWithdrawCreditTimePerDay"
            v-model="form.maxWithdrawCreditTimePerDay"
            :state="errors[0] ? false : null"
        ></b-form-input>
      </ValidationProvider>
    </b-form-group>
    <b-form-group
        label="จำนวนรอบแทงขั้นต่ำ ครบกำหนดจะสามารถถอนได้ (รอบ)"
        label-for="limitWithdrawBetTxn"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="limitWithdrawBetTxn"
          rules="required|min_value:0"
      >
        <b-form-input
            id="limitWithdrawBetTxn"
            v-model="form.limitWithdrawBetTxn"
            :state="errors[0] ? false : null"
        ></b-form-input>
      </ValidationProvider>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  page: {
    title: 'ตั้งค่าเอเย่นต์ | ตั้งค่าการถอนเงิน',
  },
  props: {
    agentData: {
      type: Object,
      default: () => ({
        minWithdrawCredit: 0,
        maxWithdrawCreditTimePerDay: 0,
        maxAutoWithdraw: 0,
        limitWithdrawBetTxn: 0,
      }),
    },
  },
  data() {
    return {
      form: {},
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      minWithdrawCredit: this.agentData.minWithdrawCredit,
      maxWithdrawCreditTimePerDay: this.agentData.maxWithdrawCreditTimePerDay,
      maxAutoWithdraw: this.agentData.maxAutoWithdraw,
      limitWithdrawBetTxn: this.agentData.limitWithdrawBetTxn
    }
  },
}
</script>

<style></style>
